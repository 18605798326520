<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter"
              parallax-active="true"
              :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              <img :src="image_head_title"/>
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div v-if="isLoading" style="height: 200px">
            <div id="loading">
              <div class="spinner"></div>
            </div>
          </div>
          <div v-else>
            <collapse-notice :active-tab="0"
                             :collapse="boardNotice.map((item) => item)"
                             icon="keyboard_arrow_down">
              <template v-for="(item, index) in boardNotice">
                <template :slot="`md-collapse-pane-${index + 1}`">
                  <p v-html="item.content" :key="index"></p>
                </template>
              </template>
            </collapse-notice>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Mixins from "@/plugins/basicMixins";
  import {
    CollapseNotice
  } from "@/components";

  export default {
    mixins: [Mixins.HeaderImage],
    bodyClass: "ac-news-notice",
    components: {
      CollapseNotice
    },
    computed: {
      ...mapGetters([
        "locale",
        "language",
        "base",
        "boardNotice",
        "isLoading",
      ]),
    },
    mounted() {
      this.GET_NOTICE_DATA();
    },
    watch: {
      locale() {
        this.GET_NOTICE_DATA();
      }
    },
    methods: {
      ...mapActions([
        'GET_NOTICE_DATA',
      ]),
    },
    data() {
      return {
        image_head_bg: require("@/assets/img/pages/news/04_news_bg1-2.png"),
        image_head_title: require("@/assets/img/pages/news/notice.png"),

        notice_type_announce: require("@/assets/img/pages/news/notice_announce.png"),
        notice_type_guide: require("@/assets/img/pages/news/notice_guide.png"),
        notice_type_maintenance: require("@/assets/img/pages/news/notice_maintenance.png"),

      };
    },
  }
</script>

<style lang="scss">
  .ac-news-notice {
    .main-raised {
      background-color: #18182f;
      min-height: 50vh;

      .section {
        padding: 50px 0;
      }
    }
  }
</style>